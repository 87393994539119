/** Variables ** =========================================================== **/

$neon: #c0ff00;
$gold: #b98b00;
$blue-grey: #5d8aa0;
$dark: #141414;
$medium: #696969;
$light: #ababab;
$base: #fafafa;

/** Branded **/
$intel-blue: #0071c5;
$keen-yellow: #fdd02f;

/** Hamburgers ** =========================================================== **/

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  padding: 8px 0 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger-box {
  width: 30px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 24px;
  height: 1px;
  background-color: #fff;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -6px;
}

.hamburger-inner::after {
  bottom: -6px;
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 6px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 12px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.is-active .hamburger--spring .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent;
}

.is-active .hamburger--spring .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.is-active .hamburger--spring .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

/** test **/

#nav {
  position: relative;
}

#nav button {
  width: 60px;
}

.overlay-nav-container {
  pointer-events: none;
  position: fixed;
  background: $dark;
  z-index: 999;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
}

.is-active .overlay-nav-container {
  pointer-events: auto;
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.overlay-nav-container a {
  font-size: 25px;
  color: #fff;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 1px;
}

.overlay-nav-container ul li {
  padding: 4px 0;
}

.nav-wrap  {
  margin: 0 auto;
  width: 100%;
  display: flex;
}

/** Global ** =========================================================== **/

a, a:hover, a:active, a:focus {
   outline: 0;
   text-decoration: none;
   transition: all 0.15s ease-in-out;
   -webkit-transition: all 0.15s ease-in-out;
   -moz-transition: all 0.15s ease-in-out;
   -o-transition: all 0.15s ease-in-out;
}

a:hover,
a:active,
a:focus {
  opacity: 0.7;
  -moz-opacity: 0.7;
  -webkit-opacity: 0.7;
}

p a {
  border-bottom: 1px solid;
}

.navbar-toggler:active,
.navbar-toggler:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus {
    outline: 0;
    box-shadow: none;
}

input::-moz-focus-inner {
  border: 0;
}

.next {
  font-size: .85em;
  padding: 20px 0;

  a {
    border: 0;
  }

  span {
    margin-left: 15px;
  }
}

.dark .next {
  a {
    color: #aaa;
  }
}

.opacity-hidden {
  opacity: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
}

.opacity-visible {
  opacity: 1.0;
  -moz-opacity: 1.0;
  -webkit-opacity: 1.0;
}

/** Helpers ** =========================================================== **/

.pad {
  padding: 8% 0;
}

.margin-auto {
  display: block;
  margin: 0 auto;
}

.push-down {
  padding-bottom: 10%;
}

.push-up {
  padding-top: 10%;
}

/** TYP ** =========================================================== **/

/*

Right arrow: &#10230;
Left arrow: &#10229;

*/

/** Overpass avial 200, 400, 600 **/

body, html {
  font-family: 'Overpass', sans-serif;
  font-weight: 400;
  color: $dark;
  background: $base;
}

p {
  letter-spacing: .05rem;
}

p.credits {
  font-size: .75rem;
  color: #aaa;
  letter-spacing: .08rem;
  padding: 10px 0;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  color: #444;
}

strong {
  font-weight: 600;
}

/** Nav ** =========================================================== **/

.navbar-toggler {
  border: 0;
  border-radius: none;
}

.navbar {
  letter-spacing: .08rem;
  background: $dark;
}

.nav-link,
.design .navbar a, .design .navbar .nav-link {
  padding: 0;
  margin: 0;
}

.navbar {
  .nav-link {
    font-size: 1rem;
    line-height: normal;
  }

  .navbar-collapse {
    padding: 1rem 0;
    text-align: center;
  }
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.is-active .logo-mark {
  opacity: 0.1;
  -moz-opacity: 0.1;
  -webkit-opacity: 0.1;
}

.logo-mark {
  height: 30px;
  width: 33px;
  opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
}

.design .navbar a:hover,
.design .navbar .nav-link:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  -webkit-opacity: 0.8;
  color: $dark;
}

.design .navbar.light a:hover,
.design .navbar.light.nav-link:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  -webkit-opacity: 0.8;
  color: $dark;
}

.design .navbar .nav-link:focus,
.design .navbar .nav-link:active {
  color: #dadada;
}

/** Layout ** =========================================================== **/

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.landing,
.navbar {
  transition: all 0.45s ease-in-out;
  -webkit-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
}

.landing {
  background-color: #ff00d3;
}

.landing-block,
.project-block {
  padding: 40vw 0;
}

.detail-block {
  padding: 8% 0;
  color: $medium;
}

.detail-block {
  h1, h2 {
    font-size: 1.4em;
    margin-bottom: .85em;
    font-weight: 400;
    letter-spacing: .05em;
  }

  h3 {
    font-size: 1.2em;
    margin-bottom: .85em;
    font-weight: 400;
    letter-spacing: .05em;
  }

  p {
    font-weight: 200;
    line-height: 1.8em;
    font-size: .9em;
  }

  ul {
    font-weight: 200;
    padding: 0;
  }

  ul li {
    list-style-type: none;
    font-weight: 200;
    letter-spacing: .05em;
    margin: .5em 0;
    color: $light;
    font-size: .9em;
  }
}

.light {
  background-color: $base;
}

.blue {
  background-color: #1590b5;
  color: #fff;
}

.blue a {
  color: #fff;
}

.light a {
  color: $dark;
}

/** Design ** =========================================================== **/

.design {
  .masthead.dark {
    background: $dark;
    color: $base;
  }

  .masthead a {
    border-bottom: 1px solid;
  }

  .masthead.dark a {
    border-bottom: 1px solid;
    color: #fff;
  }

  .masthead a.arrow {
    border: 0;
  }

  .masthead.dark a:hover,
  .masthead.dark a:focus,
  .masthead.dark a:active {

  }

  .masthead.neon {
    background: $neon;
    color: #fff;
  }
}

.design .vertical-align {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.design .project-block.masthead {
  /* padding: 25% 0; */
}

.masthead p {
  font-weight: 200;
  line-height: 1.75em;
  font-size: 1.25em;
}

.masthead p.jumbo {
  line-height: 1.75em;
  font-size: 1.25em;
}

/** Brand specific ** =========================================================== **/

.intel-blue {
  background: $intel-blue;
  color: #fff;
}

.keen-yellow {
  background: $keen-yellow;
  color: #000;
}

/** Home page ** =========================================================== **/

.pink {
  background: #ffbdcf;
  color: #fff;
}

.pink a {
  color: #fff;
}

.contact-wrap {
  background: #ffbdcf;
}

.contact-wrap {
  #contact {
    background: url("../img/footer-bg.jpg") center center no-repeat;
    background-size: cover;
    max-width: 2000px;
    margin: 0 auto;
    display: flex;
  }
}


/** Footer ** =========================================================== **/

.copy {

  p {
    font-size: .75rem;
    font-weight: 200;
    letter-spacing: .05rem;
  }
}

footer.copy {
  padding: 2% 0;
}

/** Queries ** =========================================================== **/

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

  .design .vertical-align {
    height: 100%;
  }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  /** Nav **/

  .nav-wrap  {
    max-width: 1900px;
  }

  .navbar-nav {
    padding: .75vw;
  }

  .navbar {
    padding: 1vw 3.25vw;
  }

  .design .navbar {
    a, .nav-link {
      color: #fff;
    }

    .nav-link {
      display: inline-block;
      margin: 0 0 0 18px;
    }

    a:hover,
    .nav-link:hover,
    a:focus,
    .nav-link:hover,
    a:active {
      opacity: 0.8;
      -moz-opacity: 0.8;
      -webkit-opacity: 0.8;
      color: #fff;
    }

    .nav-link:hover,
    .nav-link:focus,
    .nav-link:active {
      border-bottom: 1px solid;
    }
  }

  .navbar {
    .nav-link {
      font-size: .8rem;
    }

    .navbar-collapse {
      padding: 0;
      text-align: center;
    }
  }

  .landing .navbar {
    background-color: transparent;
  }

  .design .navbar {
    background-color: $dark;
  }

  .navbar.light {
    background-color: #fafafa;
  }

  .design .navbar.light {
    a, .nav-link {
      color: #000;
    }
  }

  .masthead p.jumbo {
    line-height: 1.4em;
    font-size: 2em;
  }

  /** Layout **/

  .landing-block,
  .project-block {
    padding: 10% 0;
  }

  .design .vertical-align {
    height: 100vh;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  /** Global **/

  .pad {
    padding: 25% 0;
  }

  .pad-sm {
    padding: 10% 0;
  }

  /** Nav **/

  .nav-up {
    top: -125px;
  }

  /** TYP **/

  p.jumbo.special-headline {
    font-size: 6em;
  }

  /** Layout **/

  .fluid-wrap {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  /** Layout **/

  .detail-block {
    h1, h2 {
      font-size: 1.8em;
    }

    h3 {
      font-size: 1.5em;
    }

    p {
      font-size: 1.1em;
    }

    ul li {
      font-size: .9em;
    }
  }

  /** Design **/

  .masthead p {
    font-size: 1.6em;
  }

  .masthead p.jumbo {
    font-size: 2.5em;
  }

  .masthead p.jumbo.special-headline {
    font-size: 6em;
  }

}

// large (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .navbar {
    .nav-link {
      font-size: .9rem;
    }
  }

  .container.large {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .masthead p.jumbo {
    line-height: 1.3em;
    font-size: 3em;
    margin-bottom: .5em;
  }

}

// large (large desktops, 1440px and up)
@media (min-width: 1440px) {

  .logo-mark {
    height: 44px;
    width: 48px;
  }

  .masthead p.jumbo {
    line-height: 1.3em;
    font-size: 3.5em;
    margin-bottom: .5em;
  }

}
